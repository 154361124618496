import 'vueperslides/dist/vueperslides.css'
import Vue from 'vue/dist/vue.esm';
import { VueperSlides, VueperSlide } from 'vueperslides'


document.addEventListener('DOMContentLoaded', () => {
    if( document.getElementById("slider-app")) {
        new Vue({
            el: '#slider-app',
            components: {VueperSlides, VueperSlide},
            data: () => ({
                slides: [
                    {
                        title: 'Slide #1',
                        content: 'Slide content.'
                    }
                ]
            })
        });
    }

    if( document.getElementById("back_slider")) {
        new Vue({
            el: '#back_slider',
            components: {VueperSlides, VueperSlide},
            data: () => ({
                slides: [
                    {
                        title: 'Slide #1',
                        content: 'Slide content.'
                    }
                ]
            })
        });
    }
    if( document.getElementById("back_slider_mobile")) {
        new Vue({
            el: '#back_slider_mobile',
            components: {VueperSlides, VueperSlide},
            data: () => ({
                slides: [
                    {
                        title: 'Slide #1',
                        content: 'Slide content.'
                    }
                ]
            })
        });
    }
    if( document.getElementById("slider-app-2")){
        new Vue({
            el: '#slider-app-2',
            components: {VueperSlides, VueperSlide},
            data: () => ({
                slides: [
                    {
                        title: 'Slide #1',
                        content: 'Slide content.'
                    }
                ]
            })
        });
    }
})